<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-home-analytics</v-icon>
        Site Pano Raporu
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit" ref="form">
          <v-row dense>
            <v-col sm="4">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col sm="4">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col sm="4">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                Göster
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <p v-if="iframeUrl" class="mt-3 mb-0 text-h5">
          <a :href="iframeUrl" download="rapor.pdf" target="_blank">
            Raporu cihazınıza indirmek için tıklayın.
          </a>
        </p>

        <h3 class="text-center text-danger text-uppercase" v-if="!consistent">
          Rapor tutarsız!
        </h3>

        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    const date = new Date();
    const min = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)
    );
    const max = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
    );

    return {
      iframeUrl: null,
      reportData: null,
      consistent: true,
      formData: {
        date: {
          min: min.toJSON().split("T")[0],
          max: max.toJSON().split("T")[0],
        },
      },
    };
  },
  methods: {
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate() || !this.clusterId) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const params = {
        usage_type_id: "download",
        file_type: "pdf",
        paper_size: "A4",
        date: this.formData.date,
      };

      this.isLoading = true;

      return this.$api
        .query(`reports/cluster-board/${this.clusterId}`, { params })
        .then((response) => {
          // eslint-disable-next-line
          this.reportData = Object.assign({}, response.data.data);
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.reportData[index] = response.data.data[index];
            }
          }
          this.reportData.start_date = new Date(
            this.formData.date.min
          ).toLocaleDateString();
          this.reportData.end_date = new Date(
            this.formData.date.max
          ).toLocaleDateString();
          for (const index in this.reportData.past_periods) {
            const item = this.reportData.past_periods[index];

            item.amount = this.numberToLocaleFormat(item.amount);
          }
          for (const index in this.reportData.current_periods) {
            const item = this.reportData.current_periods[index];

            item.amount = this.numberToLocaleFormat(item.amount);
          }
          for (const index in this.reportData.expenses) {
            const item = this.reportData.expenses[index];

            item.amount = this.numberToLocaleFormat(item.amount);
          }
          for (const index in this.reportData.incomes) {
            const item = this.reportData.incomes[index];

            item.amount = this.numberToLocaleFormat(item.amount);
          }
          for (const index in this.reportData.total_sums) {
            this.reportData.total_sums[index] = this.numberToLocaleFormat(
              this.reportData.total_sums[index]
            );
          }

          this.consistent = response.data.data.consistent;

          if (!this.consistent) {
            this.$toast.warning("Rapor tutarsız.");
          }

          return this.reportData;
        })
        .then(() => {
          const params = {
            file_type: "pdf",
          };
          params.print_template = "AuditReport";
          params.receiptData = this.reportData;

          params.paper_size = "A4";
          params.copies = 3;

          return this.$api.post("print-file", params).then((response) => {
            this.iframeUrl = response.data.data.url;
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
